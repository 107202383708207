<template>
    <div class="">
        <div class="relative" >
        <span class="bg-white absolute top-2 left-4" v-if="field">{{placeholder}}</span>
        <input
        :value="field" 
        v-on:input="updateValue($event.target.value)"
        :type="type"
        style="border-color:none !important;box-shadow:none !important"
        :class="`block border border-gray-200 rounded-xl item-center w-full p-2 px-4  text-xs ${field ? ' pt-8' :' pt-4 pb-4'}`"
        :name="field"
        :placeholder="placeholder" />
        </div>
        
    </div>
</template>

<script>
export default {
    props:['field','placeholder','type'],
    methods: {
        updateValue: function (field) {
            this.$emit('input', field);
        }
    }
}
</script>