import { render, staticRenderFns } from "./customerDashboard.vue?vue&type=template&id=a80cd566&scoped=true"
import script from "./customerDashboard.vue?vue&type=script&lang=js"
export * from "./customerDashboard.vue?vue&type=script&lang=js"
import style0 from "./customerDashboard.vue?vue&type=style&index=0&id=a80cd566&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a80cd566",
  null
  
)

export default component.exports